/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router';

/** @namespace Scandi/Component/HreflangUpdater/Component */
export class HreflangUpdaterComponent extends PureComponent {
    __construct(props) {
        super.__construct(props);

        // Define the hreflang mapping
        this.hreflangLinks = [
            { lang: 'x-default', url: 'https://www.zaynmyza.com' },
            { lang: 'en-BD', url: 'https://www.zaynmyza.com/bd' },
            { lang: 'en-IN', url: 'https://www.zaynmyza.com/in' },
            { lang: 'en-AE', url: 'https://www.zaynmyza.com/ae' },
            { lang: 'en-SA', url: 'https://www.zaynmyza.com/sa' },
            { lang: 'en-PK', url: 'https://www.zaynmyza.com/pk' },
            { lang: 'en-OM', url: 'https://www.zaynmyza.com/om' },
            { lang: 'en-QA', url: 'https://www.zaynmyza.com/qa' },
            { lang: 'en-BH', url: 'https://www.zaynmyza.com/bh' },
            { lang: 'en-KW', url: 'https://www.zaynmyza.com/kw' }
        ];
    }

    // Lifecycle method to update the hreflang tags when component mounts or updates
    componentDidMount() {
        this.updateHreflangTags();
        window.addEventListener('popstate', this.updateHreflangTags);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.updateHreflangTags();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.updateHreflangTags);
    }

    // Function to update hreflang tags
    updateHreflangTags = () => {
        // Remove existing hreflang tags
        document.querySelectorAll('link[rel="alternate"]').forEach((el) => el.remove());

        // Get current page path (example: "/ae/sale-gcc.html")
        // eslint-disable-next-line fp/no-let
        let currentPath = this.props.location.pathname;

        // Define the locale prefixes to be removed
        const localePrefixes = ['/bd', '/in', '/ae', '/sa', '/pk', '/om', '/qa', '/bh', '/kw'];

        // Remove the locale prefix while preserving the leading slash "/"
        localePrefixes.forEach((prefix) => {
            if (currentPath.startsWith(`${prefix }/`)) {
                currentPath = currentPath.replace(prefix, '');
            }
        });

        // Ensure the correct path by adding a leading slash if it's missing
        if (!currentPath.startsWith('/')) {
            currentPath = `/${ currentPath}`;
        }

        // Loop through the hreflang links and generate correct URLs
        this.hreflangLinks.forEach(({ lang, url }) => {
            const linkElement = document.createElement('link');
            linkElement.rel = 'alternate';
            linkElement.hreflang = lang;

            // Construct the final href with proper leading slash
            linkElement.href = `${url}${currentPath}`;
            document.head.appendChild(linkElement);
        });
    };

    render() {
        return null; // This component doesn't render anything in the DOM
    }
}

export default withRouter(HreflangUpdaterComponent);
