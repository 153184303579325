/* eslint-disable react/forbid-elements */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CartIcon from 'Component/CartIcon';
import { MixType } from 'Type/Common.type';
import { LayoutType } from 'Type/Layout.type';
import { ProductType } from 'Type/ProductList.type';

import './AddToCart.style';

/**
 * Button for adding product to Cart
 * @class AddToCart
 * @namespace Scandi/Component/AddToCart/Component
 */
export class AddToCartComponent extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool.isRequired,
        isAdding: PropTypes.bool.isRequired,
        handleButtonClick: PropTypes.func.isRequired,

        // Customization
        isIconEnabled: PropTypes.bool.isRequired,
        mix: MixType.isRequired,
        layout: LayoutType.isRequired,
        product: ProductType,
        countryCode: PropTypes.string.isRequired,
        getAttribute: PropTypes.func.isRequired
    };

    static defaultProps = {
        product: {}
    };

    renderCartIcon() {
        const { isIconEnabled } = this.props;

        if (!isIconEnabled) {
            return null;
        }

        return <CartIcon />;
    }

    renderOhsogoButton() {
        const {
            mix,
            product,
            getAttribute,
            countryCode
        } = this.props;

        const { attributes } = product;
        if (!attributes) {
            return null;
        }
        const ohsogo_link = getAttribute('ohsogo_link');

        if (!ohsogo_link || countryCode !== 'bd') {
            return null;
        }

        return (
                <a
                  href={ ohsogo_link.attribute_value }
                  block="Button AddToCart"
                  mix={ mix }
                >
                    <span>Buy Now</span>
                </a>
        );
    }

    render() {
        const {
            mix,
            layout,
            isDisabled,
            isAdding,
            handleButtonClick,
            getAttribute,
            countryCode
        } = this.props;

        const ohsogo_link = getAttribute('ohsogo_link');

        if (ohsogo_link && ohsogo_link.attribute_value && countryCode === 'bd') {
            return this.renderOhsogoButton();
        }

        // eslint-disable-next-line no-nested-ternary
        const label = isAdding ? __('Adding...') : isDisabled ? __('Sold Out') : __('Add to cart');

        return (
            <button
              onClick={ handleButtonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { layout } }
              disabled={ isDisabled || isAdding }
            >
                { this.renderCartIcon() }
                <span>{ label }</span>
            </button>
        );
    }
}

export default AddToCartComponent;
