/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { lazy } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';

import { SALE_TIMER } from '../component/SaleTimer/SaleTimer.config';

export const SaleTimer = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-sale-timer" */
    '../component/SaleTimer'
));

const renderMap = (originalMember) => ({
    [SALE_TIMER]: {
        component: SaleTimer
    },
    ...originalMember
});

const renderContent = (args, callback, instance) => {
    const { type } = instance.props;

    if (type !== SALE_TIMER) {
        return callback.apply(instance, args);
    }

    const {
        component: Widget,
        fallback
    } = instance.renderMap[type] || {};

    if (Widget !== undefined) {
        return (
            <RenderWhenVisible fallback={ fallback }>
                <Widget { ...instance.props } />
            </RenderWhenVisible>
        );
    }

    return null;
};

export default {
    'Scandi/Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            renderContent
        }
    }
};
